/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

// import ASScroll from '@ashthornton/asscroll'
//
// export const onInitialClientRender = () => {
//   const smoothScroll = new ASScroll()
//   smoothScroll.enable()
//   window.onload = () => {
//     console.log('aaaa');
//   }
// }

import Scrollbar from 'smooth-scrollbar'
import { gsap, SlowMo } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { isMobile, isTablet } from "react-device-detect"

// // in gastby-browser.js
// exports.shouldUpdateScroll = ({
//   routerProps: { location },
//   getSavedScrollPosition,
// }) => {
//   const { pathname } = location
//   // list of routes for the scroll-to-top-hook
//   const scrollToTopRoutes = [`/privacy-policy`, `/page-2`]
//   // if the new route is part of the list above, scroll to top (0, 0)
//   if (scrollToTopRoutes.indexOf(pathname) !== -1) {
//     window.scrollTo(0, 0)
//   }
//
//   return false
// }

export const shouldUpdateScroll = () => {
  console.log("shouldUpdateScroll");
  window.scrollTo(0, 0)
}

export const onRouteUpdate = () => {
  window.scrollTo(0, 0)

  if(isMobile || isTablet) {
    const scroller = document.querySelector('#scroller')
    if(scroller) {
      scroller.style.height = "auto"
    }
  }

  // gsap.registerPlugin(ScrollTrigger);
  //
  // const scroller = document.querySelector('#scroller')
  // if(scroller) {
  //   const bodyScrollBar = Scrollbar.init(scroller, { damping: 0.1, delegateTo: document, alwaysShowTracks: true })
  //
  //   ScrollTrigger.scrollerProxy("#scroller", {
  //     scrollTop(value) {
  //       if (arguments.length) {
  //         bodyScrollBar.scrollTop = value;
  //       }
  //       return bodyScrollBar.scrollTop;
  //     }
  //   });
  //   bodyScrollBar.addListener(ScrollTrigger.update);
  //   ScrollTrigger.defaults({ scroller: scroller });
  //
  //   gsap.utils.toArray('.fade').forEach(section => {
  //     gsap.from(section, {
  //       y: 125,
  //       opacity: 0,
  //       scrollTrigger: {
  //         trigger: section,
  //         start: "top 60%",
  //         toggleActions: "play none none none"
  //       },
  //     });
  //   });
  //
  //
  //
  //   gsap.to(".products-list-bg", {
  //     opacity: 1,
  //     scrollTrigger: {
  //       trigger: ".products-list",
  //       start: "top bottom",
  //       end: () => "+=500",
  //       // toggleActions: "play reverse",
  //       markers: false,
  //       scrub: true
  //     },
  //   });
  //
  //   gsap.to('section.category-intro h1', {
  //     y: 250,
  //     scrollTrigger: {
  //       trigger: "section.category-intro",
  //       start: "top top",
  //       end: () => "+=" + window.innerHeight*2,
  //       scrub: true,
  //     }
  //   });
  //
  //   gsap.to('.grid-of-dots', {
  //     y: -250,
  //     scrollTrigger: {
  //       trigger: ".grid-of-dots",
  //       start: "top top",
  //       end: () => "+=" + window.innerHeight*2,
  //       scrub: true,
  //     }
  //   });
  //
  // } else {

    // gsap.to('section.category-intro h1', {
    //   y: 250,
    //   scrollTrigger: {
    //     trigger: "section.category-intro",
    //     start: "top top",
    //     end: () => "+=" + window.innerHeight,
    //     scrub: true,
    //   }
    // });

    // gsap.utils.toArray('.fade').forEach(section => {
    //   gsap.from(section, {
    //     y: 125,
    //     opacity: 0,
    //     scrollTrigger: {
    //       trigger: section,
    //       start: "top 75%",
    //       toggleActions: "play none none none",
    //       // markers:true
    //     },
    //   });
    // });
    // gsap.utils.toArray('.grid-of-dots').forEach(section => {
    //   gsap.from(section, {
    //     y: 250,
    //     // opacity: 0,
    //     scrollTrigger: {
    //       trigger: ".grid-of-dots",
    //       start: "top top",
    //       end: () => "+=" + window.innerHeight,
    //       scrub: true,
    //     }
    //   });
    // });
  // }


}
